import React from "react";
import PropTypes from "prop-types";
import {componentMap} from '../../../util/componentMap';

class DynamicHTMLComponent extends React.Component {

  getStartTag = (component, classNames, contentBaseURL) => {
    const tag = componentMap[component];
    const classes = classNames ? classNames : "";
    if (tag) {
      if(tag === "img") {
        return "<img src=\""+ contentBaseURL + "img/";
      }

      if (tag === "html") {
        return "<iframe id=\"dynamicHTMLContentId\" class=\"iframeContent\" onload=\"adjustHeight();\" src=\"" + contentBaseURL +"html/";
      }

      return "<" + tag + " class=\"" + classes + "\">";
    }
    return "<p class=\"" + classes + "\">"
  }

  getEndTag = (component) => {
    const tag = componentMap[component];
    if (tag) {
      if(tag === "img"){
        return "\"/>";
      }

      if (tag === "html"){
        return "\">";
      }

      return "</" + tag + ">"
    }
    return "</p>";
  }

  getComponentToRender = (content, contentBaseURL) => {
    return  this.getStartTag(content.component, content.classNames, contentBaseURL) +
          content.data +
          this.getEndTag(content.component);
  }

  render(){
    const {content, contentBaseURL} = this.props;
    const DynamicComponent= this.getComponentToRender(content, contentBaseURL);
    return (
        <>
          {content &&
            <div dangerouslySetInnerHTML={{ __html: DynamicComponent }} />
          }
        </>
      )
    }
}

DynamicHTMLComponent.propTypes = {
  content:PropTypes.object,
  contentBaseURL:PropTypes.string
};

export default DynamicHTMLComponent;
